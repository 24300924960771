import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import { graphql } from 'gatsby';
import Gallery from '@components/Gallery/Gallery';
export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "media/art-vr/gallery" }
      }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`art/vr`}</h1>
    <p className="lead">Programmable Virtual Reality Art Gallery</p>
    <p>{`Curate your gallery by choosing from millions of images in the public domain. Shuffle paintings to find inspiration or new favorite artists `}{`—`}{` or load in all Van Gogh's for a deep dive.`}</p>
    <h2>{`Screenshots`}</h2>
    <Gallery altText={'Screenshot from art/VR gallery'} aspectRatio={1.88} caption={'Stills from an unfinished open air gallery.'} items={props.data.allFile.edges.map(edge => edge.node.childImageSharp.gatsbyImageData)} mdxType="Gallery" />
    <h2>{`Art`}</h2>
    <ul>
      <li parentName="ul">{`Images are provided courtesy of the Metropolitan Museum of Art `}<a parentName="li" {...{
          "href": "https://www.metmuseum.org/about-the-met/policies-and-documents/open-access"
        }}>{`Open Access Initiative`}</a>{`.`}</li>
    </ul>
    <h2>{`Technology`}</h2>
    <ul>
      <li parentName="ul">{`Level and VR design built in Unity`}</li>
      <li parentName="ul">{`API built using ruby/rails`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      